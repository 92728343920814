html,
body {
  width: 100%;
  overflow-x: hidden;
}

.home-landing {
  position: relative;
  background: url("../images/mountain.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 100px;
  width: 100%;
  height: 93vh;
  opacity: 0;
}

.home-landing-text-container {
  position: absolute;
  top: 30%;
  left: 50%;
  width: clamp(300px, 50%, 800px);
  transform: translate(-50%, -50%);
  text-align: left;
}

.home-landing-text-container h2 {
  font-size: 5rem;
  font-size: clamp(2.5rem, 5vw, 5rem);
  line-height: 0.9;
  margin: 1.5rem 0;
}
.home-landing-text-container p {
  font-size: 1.1rem;
  font-size: clamp(1rem, 2vw, 1.1rem);
  margin-top: 0;
}

button {
  background: rgba(0, 0, 0, 0);
  border: 2px solid var(--color-dark);
  padding: 0.8rem 1.4rem;
  font-size: 1rem;
  transition: all 0.3s;
  margin: 1rem 0;
}
button:hover {
  background: var(--color-dark);
  color: var(--color-light);
}

.vertical-text {
  display: flex;
  position: absolute;
  top: 40%;
  font-size: 1rem;
}
.vertical-text > div {
  margin: 0 1.5rem;
}
.vertical-text.left-side {
  left: -120px;
  transform: translate(0, -50%) rotate(-90deg);
}
.vertical-text.right-side {
  right: -140px;
  transform: translate(0, -50%) rotate(90deg);
}

.scroll-down-indicator {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 4rem;
  animation: hover 3s infinite;
  cursor: pointer;
}
@keyframes hover {
  0% {
    bottom: 60px;
  }
  50% {
    bottom: 40px;
  }
  100% {
    bottom: 60px;
  }
}

/* ---------------------
Recent Trips
------------------------ */

.recent-trips {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 3rem;
}
.recent-trips h2 {
  font-size: clamp(2rem, 5vw, 2.5rem);
}

.recent-trips-card-container {
  display: flex;
  justify-content: space-between;
  width: clamp(200px, 87%, 950px);
  margin-top: 1rem;
  margin-bottom: 5rem;
}
.recent-trips-card {
  position: relative;
  background: var(--color-light);
  height: clamp(250px, 30vw, 350px);
  width: clamp(178.57px, 21.43vw, 250px);
  transition: all 0.3s;
}
.recent-trips-card::after {
  content: " ";
  height: clamp(60px, 5vw, 75px);
  width: clamp(42.86px, 4vw, 50px);
  background: var(--color-dark);
  position: absolute;
  bottom: 30px;
  left: 100%;
}
.recent-trips-card::before {
  content: "→";
  color: var(--color-light);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  height: clamp(60px, 5vw, 75px);
  width: clamp(42.86px, 4vw, 50px);
  background: var(--color-dark);
  position: absolute;
  z-index: 5;
  bottom: 30px;
  left: 50%;
  opacity: 0;
  transition: all 0.2s;
}

.recent-trips-card-text-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 30px;
  background: var(--color-dark);
  height: 75px;
  width: 65%;
  height: clamp(60px, 5vw, 75px);
  padding-left: 1.5rem;
  z-index: 3;
  transition: all 0.3s;
}
.recent-trips-card-text-container h2 {
  font-size: 1.7rem;
  font-size: clamp(1.3rem, 2vw, 1.7rem);
  margin: 0;
  color: var(--color-light);
}
.recent-trips-card-text-container p {
  font-size: 1.1rem;
  margin: 0;
  color: var(--color-light-accent);
  font-size: clamp(0.9rem, 1vw, 1.1rem);
}
.recent-trips-card img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  transition: all 0.3s;
  object-fit: cover;
}
.recent-trips-card-container:hover .recent-trips-card,
.recent-trips-card-container:focus .recent-trips-card {
  opacity: 0.3;
}
.recent-trips-card-container:hover .recent-trips-card:hover,
.recent-trips-card-container:focus .recent-trips-card:focus {
  opacity: 1;
}
.recent-trips-card:hover .recent-trips-card-text-container,
.recent-trips-card:focus .recent-trips-card-text-container {
  width: 100%;
}
.recent-trips-card:hover::before,
.recent-trips-card:focus::before {
  opacity: 1;
  left: 100%;
}

.info-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 5rem;
}

.info-banner-text-container {
  width: clamp(200px, 70%, 800px);
  text-align: center;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

.info-banner-text-container h2 {
  font-size: clamp(2rem, 5vw, 2.5rem);
  margin-bottom: 0;
}

.info-banner-text-container p {
  margin-top: 0;
  font-size: clamp(1rem, 2vw, 1.2rem);
}

.info-banner-card-container {
  display: flex;
  width: clamp(200px, 100%, 1136px);
  margin: auto;
  justify-content: center;
}

.info-banner-card {
  width: 300px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-banner-card img {
  height: 70px;
  width: 70px;
  background-size: cover;
}

.info-banner-card h2 {
  margin-bottom: 0;
}

.info-banner-card p {
  margin-top: 0.5rem;
}

.info-banner-2 {
  background: var(--color-dark);
}
.info-banner-2-wrapper {
  color: var(--color-light);
  padding-top: 3rem;
  padding-bottom: 5rem;
  width: clamp(200px, 100%, 1136px);
  margin: 0 auto;
}
.info-banner-2 p {
  color: var(--color-light-accent);
}

.info-banner-card-number-container {
  display: flex;
  justify-content: space-evenly;
  width: clamp(200px, 100%, 700px);
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

.info-banner-card-number {
  margin: 20px;
  line-height: 0.6;
  text-align: center;
}

.info-banner-card-number h2 {
  font-size: 2.3rem;
}

.info-banner-card-number p {
  color: var(--color-light-accent);
}

.info-banner-card-photo {
  width: 300px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-photo-icon {
  position: relative;
  height: 250px;
  width: 250px;
  background: var(--color-dark-accent);
  margin-bottom: 1rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.card-photo-icon img {
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
}
.social-button-container {
  position: absolute;
  z-index: 2;
  width: 90%;
  display: flex;
  justify-content: center;
  opacity: 0;
  transform: scale(0.5);
  transition: all 0.3s;
}
.social-button-container img {
  position: relative;
  height: 40px;
  width: 40px;
  object-fit: cover;
  margin: 5px;
  cursor: pointer;
}
.card-photo-icon:hover .social-button-container {
  opacity: 0.8;
  transform: scale(1);
}

.info-banner-card-photo h2 {
  margin-bottom: 0;
  line-height: 0.5;
}

.info-banner-card-photo p {
  margin-top: 1rem;
}

.image-divider {
  background: url("../images/forrestMountain.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 800px;
}

.info-banner-testimonial-container {
  display: flex;
  width: 1000px;
  width: clamp(200px, 80%, 1000px);
  margin: 2rem auto;
}

.testimonial-text {
  width: clamp(200px, 80%, 1000px);
}

.info-banner-testimonial-container h2 {
  margin-bottom: 0;
}
.info-banner-testimonial-container h3 {
  font-size: 1.2rem;
  font-size: clamp(0.9rem, 3.5vw, 1.2rem);
}
.info-banner-testimonial-container p {
  margin-top: 0;
  margin-bottom: 1.2rem;
}

.testimonial-image-container {
  width: 100px;
  height: 100px;
  height: clamp(40px, 10vw, 100px);
  width: clamp(40px, 10vw, 100px);
  margin-right: 10px;
  position: relative;
}

.testimonial-image {
  position: absolute;
  top: 20px;
  height: clamp(40px, 10vw, 100px);
  width: clamp(40px, 10vw, 100px);
  border-radius: 50%;
  position: relative;
  background: url("../images/portraits/portrait1.png");
  background-size: cover;
  background-position-y: -10px;
}

.ratings {
  display: flex;
  flex-direction: row;
  width: 800px;
  width: clamp(400px, 70vw, 800px);
  flex-wrap: wrap;
}

.rating {
  display: flex;
  align-items: center;
  padding: 0rem 0.5rem;
  width: clamp(290px, 50vw, 325px);
  position: relative;
}
.rating:nth-of-type(odd) {
  margin-right: 100px;
}

.rating-line-container {
  display: flex;
  position: absolute;
  right: 0;
}

.rating-line {
  background: var(--color-light-accent);
  height: 6px;
  width: 30px;
  margin: 0 2px;
  width: 15px;
  width: clamp(0px, 4vw, 30px);
}
.rating-line.filled {
  background: var(--color-dark);
}

.rating-line:first-of-type {
  margin-left: 50px;
}

@media screen and (max-width: 425px) {
  .ratings {
    width: 100%;
  }
  .rating {
    width: 100%;
  }
}

.contact-banner {
  background: url("../images/hawaii.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 800px;
}

.wrapper {
  position: relative;
  width: clamp(200px, 100%, 1136px);
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: right;
}

.contact-container {
  position: relative;
  width: 400px;
  height: 600px;
  width: clamp(200px, 70%, 400px);
  background: var(--color-light);
  text-align: left;
  padding: 20px 30px;
  padding-bottom: 50px;
  top: 50%;
  right: 5%;
  transform: translate(0, -50%);
}
.contact-container h2 {
  margin-bottom: 0;
  font-size: 1.5rem;
  color: var(--color-dark);
}
.contact-container p {
  margin-top: 0;
  font-size: 1rem;
}

.input-container {
  display: flex;
  flex-direction: column;
}

label {
  margin: 0.5rem 0;
  font-size: 1.1rem;
  color: var(--color-dark-accent);
  font-family: var(--font-secondary);
  font-weight: 600;
}

input {
  width: 95%;
  font-size: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 0.7rem;
  border: 1px solid var(--color-light-accent);
  border-radius: 5px;
}

input:focus {
  border-color: var(--color-dark-accent);
}

@media screen and (max-width: 1350px) {
  .home-landing {
    background-position-y: 200px;
  }
}
@media screen and (max-width: 750px) {
  .rating:nth-of-type(odd) {
    margin-right: 0px;
  }
  .home-landing-text-container {
    top: 40%;
  }
  .vertical-text {
    display: none;
  }
  .recent-trips-card-container {
    flex-direction: column;
    align-items: center;
    height: 800px;
  }
  .info-banner-card-container {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .footer-wrapper {
    flex-direction: column;
    align-items: center;
  }
}
