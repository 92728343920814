@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Vollkorn:wght@400;500;600;700&display=swap");

/* * {
  margin: 0;
  box-sizing: 0;
  padding: 0;
} */
:root {
  --font-primary: "Vollkorn", serif;
  --font-secondary: "Open Sans", sans-serif;

  --color-dark: rgb(20, 20, 20);
  --color-light: rgb(255, 255, 255);
  --color-light-accent: rgb(204, 204, 204);
  --color-dark-accent: rgb(65, 65, 65);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

li {
  text-decoration: none;
  text-transform: uppercase;
  list-style: none;
}

a,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
  color: var(--color-dark);
}

a:visited {
  color: var(--color-dark);
}

body {
  font-family: var(--font-primary);
  text-decoration: none;
  visibility: hidden;
}

.stop-scrolling {
  overflow: hidden;
}

p {
  font-family: var(--font-secondary);
  color: var(--color-dark-accent);
}

b {
  color: var(--color-light-accent);
  text-decoration: underline;
}