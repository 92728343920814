@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Vollkorn:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* * {
  margin: 0;
  box-sizing: 0;
  padding: 0;
} */
:root {
  --font-primary: "Vollkorn", serif;
  --font-secondary: "Open Sans", sans-serif;

  --color-dark: rgb(20, 20, 20);
  --color-light: rgb(255, 255, 255);
  --color-light-accent: rgb(204, 204, 204);
  --color-dark-accent: rgb(65, 65, 65);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

li {
  text-decoration: none;
  text-transform: uppercase;
  list-style: none;
}

a,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
  color: rgb(20, 20, 20);
  color: var(--color-dark);
}

a:visited {
  color: rgb(20, 20, 20);
  color: var(--color-dark);
}

body {
  font-family: "Vollkorn", serif;
  font-family: var(--font-primary);
  text-decoration: none;
  visibility: hidden;
}

.stop-scrolling {
  overflow: hidden;
}

p {
  font-family: "Open Sans", sans-serif;
  font-family: var(--font-secondary);
  color: rgb(65, 65, 65);
  color: var(--color-dark-accent);
}

b {
  color: rgb(204, 204, 204);
  color: var(--color-light-accent);
  text-decoration: underline;
}
.header-wrapper {
  position: relative;
  display: flex;
  width: clamp(200px, 100%, 1136px);
  margin: auto;
  height: 90px;
  align-items: center;
  font-family: var(--font-primary);
}

.logo-container {
  margin: 0 30px;
  font-size: 1.2rem;
  font-weight: 500;
}

nav {
  display: flex;
  justify-content: right;
  align-items: center;
  position: absolute;
  right: 0;
  margin: 0 30px;
}

nav > a,
nav > div {
  margin-left: 70px;
}

header a:hover {
  opacity: 0.5;
}

.hamburger {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.line {
  height: 2px;
  width: 20px;
  background: var(--color-dark);
  margin: 2px 0;
  transition: all 0.3s;
}

.line.half {
  width: 10px;
  margin-left: 10px;
}

.hamburger:hover .line.half {
  width: 20px;
  margin-left: 0px;
}

.hidden-menu {
  position: fixed;
  top: 0;
  right: 0;
  background: var(--color-dark);
  height: 100vh;
  min-width: 450px;
  max-width: 100%;
  z-index: 99;
  overflow: hidden;
  transition: all 0.3s;
}

.close-hidden-menu {
  color: var(--color-light);
  font-size: 3rem;
  position: absolute;
  top: 0;
  right: 0;
  margin: 6rem 4rem;
  cursor: pointer;
  transition: all 0.5s;
}
.close-hidden-menu:hover {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.hidden-menu-text-container {
  width: 1px;
  margin: 4rem;
  padding: 1rem;
  white-space: nowrap;
}

.menu-link {
  color: var(--color-light);
  font-size: 3rem;
  margin: 1rem;
  transition: all 0.3s;
}
.menu-link:hover {
  font-style: italic;
  text-decoration: line-through;
}

@media screen and (max-width: 650px) {
  .nav-link {
    display: none;
  }
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}

.home-landing {
  position: relative;
  background: url(/static/media/mountain.0b29f7c2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 100px;
  width: 100%;
  height: 93vh;
  opacity: 0;
}

.home-landing-text-container {
  position: absolute;
  top: 30%;
  left: 50%;
  width: clamp(300px, 50%, 800px);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: left;
}

.home-landing-text-container h2 {
  font-size: 5rem;
  font-size: clamp(2.5rem, 5vw, 5rem);
  line-height: 0.9;
  margin: 1.5rem 0;
}
.home-landing-text-container p {
  font-size: 1.1rem;
  font-size: clamp(1rem, 2vw, 1.1rem);
  margin-top: 0;
}

button {
  background: rgba(0, 0, 0, 0);
  border: 2px solid var(--color-dark);
  padding: 0.8rem 1.4rem;
  font-size: 1rem;
  transition: all 0.3s;
  margin: 1rem 0;
}
button:hover {
  background: var(--color-dark);
  color: var(--color-light);
}

.vertical-text {
  display: flex;
  position: absolute;
  top: 40%;
  font-size: 1rem;
}
.vertical-text > div {
  margin: 0 1.5rem;
}
.vertical-text.left-side {
  left: -120px;
  -webkit-transform: translate(0, -50%) rotate(-90deg);
          transform: translate(0, -50%) rotate(-90deg);
}
.vertical-text.right-side {
  right: -140px;
  -webkit-transform: translate(0, -50%) rotate(90deg);
          transform: translate(0, -50%) rotate(90deg);
}

.scroll-down-indicator {
  position: absolute;
  bottom: 50px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  font-size: 4rem;
  -webkit-animation: hover 3s infinite;
          animation: hover 3s infinite;
  cursor: pointer;
}
@-webkit-keyframes hover {
  0% {
    bottom: 60px;
  }
  50% {
    bottom: 40px;
  }
  100% {
    bottom: 60px;
  }
}
@keyframes hover {
  0% {
    bottom: 60px;
  }
  50% {
    bottom: 40px;
  }
  100% {
    bottom: 60px;
  }
}

/* ---------------------
Recent Trips
------------------------ */

.recent-trips {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 3rem;
}
.recent-trips h2 {
  font-size: clamp(2rem, 5vw, 2.5rem);
}

.recent-trips-card-container {
  display: flex;
  justify-content: space-between;
  width: clamp(200px, 87%, 950px);
  margin-top: 1rem;
  margin-bottom: 5rem;
}
.recent-trips-card {
  position: relative;
  background: var(--color-light);
  height: clamp(250px, 30vw, 350px);
  width: clamp(178.57px, 21.43vw, 250px);
  transition: all 0.3s;
}
.recent-trips-card::after {
  content: " ";
  height: clamp(60px, 5vw, 75px);
  width: clamp(42.86px, 4vw, 50px);
  background: var(--color-dark);
  position: absolute;
  bottom: 30px;
  left: 100%;
}
.recent-trips-card::before {
  content: "→";
  color: var(--color-light);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  height: clamp(60px, 5vw, 75px);
  width: clamp(42.86px, 4vw, 50px);
  background: var(--color-dark);
  position: absolute;
  z-index: 5;
  bottom: 30px;
  left: 50%;
  opacity: 0;
  transition: all 0.2s;
}

.recent-trips-card-text-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 30px;
  background: var(--color-dark);
  height: 75px;
  width: 65%;
  height: clamp(60px, 5vw, 75px);
  padding-left: 1.5rem;
  z-index: 3;
  transition: all 0.3s;
}
.recent-trips-card-text-container h2 {
  font-size: 1.7rem;
  font-size: clamp(1.3rem, 2vw, 1.7rem);
  margin: 0;
  color: var(--color-light);
}
.recent-trips-card-text-container p {
  font-size: 1.1rem;
  margin: 0;
  color: var(--color-light-accent);
  font-size: clamp(0.9rem, 1vw, 1.1rem);
}
.recent-trips-card img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  transition: all 0.3s;
  object-fit: cover;
}
.recent-trips-card-container:hover .recent-trips-card,
.recent-trips-card-container:focus .recent-trips-card {
  opacity: 0.3;
}
.recent-trips-card-container:hover .recent-trips-card:hover,
.recent-trips-card-container:focus .recent-trips-card:focus {
  opacity: 1;
}
.recent-trips-card:hover .recent-trips-card-text-container,
.recent-trips-card:focus .recent-trips-card-text-container {
  width: 100%;
}
.recent-trips-card:hover::before,
.recent-trips-card:focus::before {
  opacity: 1;
  left: 100%;
}

.info-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 5rem;
}

.info-banner-text-container {
  width: clamp(200px, 70%, 800px);
  text-align: center;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.info-banner-text-container h2 {
  font-size: clamp(2rem, 5vw, 2.5rem);
  margin-bottom: 0;
}

.info-banner-text-container p {
  margin-top: 0;
  font-size: clamp(1rem, 2vw, 1.2rem);
}

.info-banner-card-container {
  display: flex;
  width: clamp(200px, 100%, 1136px);
  margin: auto;
  justify-content: center;
}

.info-banner-card {
  width: 300px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-banner-card img {
  height: 70px;
  width: 70px;
  background-size: cover;
}

.info-banner-card h2 {
  margin-bottom: 0;
}

.info-banner-card p {
  margin-top: 0.5rem;
}

.info-banner-2 {
  background: var(--color-dark);
}
.info-banner-2-wrapper {
  color: var(--color-light);
  padding-top: 3rem;
  padding-bottom: 5rem;
  width: clamp(200px, 100%, 1136px);
  margin: 0 auto;
}
.info-banner-2 p {
  color: var(--color-light-accent);
}

.info-banner-card-number-container {
  display: flex;
  justify-content: space-evenly;
  width: clamp(200px, 100%, 700px);
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.info-banner-card-number {
  margin: 20px;
  line-height: 0.6;
  text-align: center;
}

.info-banner-card-number h2 {
  font-size: 2.3rem;
}

.info-banner-card-number p {
  color: var(--color-light-accent);
}

.info-banner-card-photo {
  width: 300px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-photo-icon {
  position: relative;
  height: 250px;
  width: 250px;
  background: var(--color-dark-accent);
  margin-bottom: 1rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.card-photo-icon img {
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
}
.social-button-container {
  position: absolute;
  z-index: 2;
  width: 90%;
  display: flex;
  justify-content: center;
  opacity: 0;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  transition: all 0.3s;
}
.social-button-container img {
  position: relative;
  height: 40px;
  width: 40px;
  object-fit: cover;
  margin: 5px;
  cursor: pointer;
}
.card-photo-icon:hover .social-button-container {
  opacity: 0.8;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.info-banner-card-photo h2 {
  margin-bottom: 0;
  line-height: 0.5;
}

.info-banner-card-photo p {
  margin-top: 1rem;
}

.image-divider {
  background: url(/static/media/forrestMountain.7dde78d9.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 800px;
}

.info-banner-testimonial-container {
  display: flex;
  width: 1000px;
  width: clamp(200px, 80%, 1000px);
  margin: 2rem auto;
}

.testimonial-text {
  width: clamp(200px, 80%, 1000px);
}

.info-banner-testimonial-container h2 {
  margin-bottom: 0;
}
.info-banner-testimonial-container h3 {
  font-size: 1.2rem;
  font-size: clamp(0.9rem, 3.5vw, 1.2rem);
}
.info-banner-testimonial-container p {
  margin-top: 0;
  margin-bottom: 1.2rem;
}

.testimonial-image-container {
  width: 100px;
  height: 100px;
  height: clamp(40px, 10vw, 100px);
  width: clamp(40px, 10vw, 100px);
  margin-right: 10px;
  position: relative;
}

.testimonial-image {
  position: absolute;
  top: 20px;
  height: clamp(40px, 10vw, 100px);
  width: clamp(40px, 10vw, 100px);
  border-radius: 50%;
  position: relative;
  background: url(/static/media/portrait1.0448ae8d.png);
  background-size: cover;
  background-position-y: -10px;
}

.ratings {
  display: flex;
  flex-direction: row;
  width: 800px;
  width: clamp(400px, 70vw, 800px);
  flex-wrap: wrap;
}

.rating {
  display: flex;
  align-items: center;
  padding: 0rem 0.5rem;
  width: clamp(290px, 50vw, 325px);
  position: relative;
}
.rating:nth-of-type(odd) {
  margin-right: 100px;
}

.rating-line-container {
  display: flex;
  position: absolute;
  right: 0;
}

.rating-line {
  background: var(--color-light-accent);
  height: 6px;
  width: 30px;
  margin: 0 2px;
  width: 15px;
  width: clamp(0px, 4vw, 30px);
}
.rating-line.filled {
  background: var(--color-dark);
}

.rating-line:first-of-type {
  margin-left: 50px;
}

@media screen and (max-width: 425px) {
  .ratings {
    width: 100%;
  }
  .rating {
    width: 100%;
  }
}

.contact-banner {
  background: url(/static/media/hawaii.0e31cbdb.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 800px;
}

.wrapper {
  position: relative;
  width: clamp(200px, 100%, 1136px);
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: right;
}

.contact-container {
  position: relative;
  width: 400px;
  height: 600px;
  width: clamp(200px, 70%, 400px);
  background: var(--color-light);
  text-align: left;
  padding: 20px 30px;
  padding-bottom: 50px;
  top: 50%;
  right: 5%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}
.contact-container h2 {
  margin-bottom: 0;
  font-size: 1.5rem;
  color: var(--color-dark);
}
.contact-container p {
  margin-top: 0;
  font-size: 1rem;
}

.input-container {
  display: flex;
  flex-direction: column;
}

label {
  margin: 0.5rem 0;
  font-size: 1.1rem;
  color: var(--color-dark-accent);
  font-family: var(--font-secondary);
  font-weight: 600;
}

input {
  width: 95%;
  font-size: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 0.7rem;
  border: 1px solid var(--color-light-accent);
  border-radius: 5px;
}

input:focus {
  border-color: var(--color-dark-accent);
}

@media screen and (max-width: 1350px) {
  .home-landing {
    background-position-y: 200px;
  }
}
@media screen and (max-width: 750px) {
  .rating:nth-of-type(odd) {
    margin-right: 0px;
  }
  .home-landing-text-container {
    top: 40%;
  }
  .vertical-text {
    display: none;
  }
  .recent-trips-card-container {
    flex-direction: column;
    align-items: center;
    height: 800px;
  }
  .info-banner-card-container {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .footer-wrapper {
    flex-direction: column;
    align-items: center;
  }
}

.grey-background {
  background: rgb(248, 248, 248);
}

.text-1-col {
  background: var(--color-light);
  padding: 4rem 1rem;
  padding-bottom: 7rem;
}

.text-wrapper {
  width: clamp(200px, 100%, 850px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin: auto;
}
.text-wrapper h2 {
  margin: 1.5rem 0rem;
  font-size: clamp(2rem, 5vw, 2.5rem);
}
.text-wrapper p {
  margin: 1rem 0;
  font-size: clamp(1rem, 2vw, 1.2rem);
}



.location-name {
  position: relative;
  height: min(210vh, 1900px);
  background: var(--color-light);
  display: flex;
  align-items: center;
  flex-direction: column;
  opacity: 0;
}

.location-name h2 {
  position: absolute;
  z-index: 6;
  top: 0;
  font-size: clamp(3rem, 15vw, 13rem);
  margin: 0;
  margin-top: 4rem;
  font-weight: 400;
  letter-spacing: 0.6rem;
}

.city-image-main {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 5;
  height: min(130vw, 1200px);
  width: min(90%, 1136px);
  object-fit: cover;
}

.location-support-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--color-dark);
  height: 950px;
  height: max(50%, 950px);
}
.location-support-info-card-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: min(75vw, 1136px);
  margin: auto;
  position: absolute;
  z-index: 6;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  padding-bottom: 3rem;
}
.location-support-info-card {
  width: 275px;
  height: 225px;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.location-support-info-card h3 {
  font-size: 6rem;
  font-size: clamp(3rem, 15vw, 6rem);
  font-weight: 400;
  margin: 0;
  margin-bottom: 1rem;
  color: var(--color-light);
}
.location-support-info-card p {
  margin: 0;
  color: var(--color-light-accent);
}

.experiences {
  background: var(--color-dark);
}
.experiences-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 5rem;
  width: clamp(200px, 85%, 1136px);
  margin: auto;
  padding: 5rem 0rem;
}
.experiences h2 {
  color: var(--color-light);
  font-size: clamp(3rem, 5vw, 5rem);
  width: min(80%, 500px);
  margin: 3rem 2rem;
}
.p-container {
  margin: 1rem 2rem;
  display: flex;
  justify-content: space-evenly;
}
.experiences p {
  width: 300px;
  color: var(--color-light-accent);
  font-size: 1.2rem;
}

.experiences .p-container-first:first-of-type::first-letter {
  color: var(--color-light);
  font-family: var(--font-primary);
  float: left;
  font-size: 5.5rem;
  line-height: 60px;
  padding-top: 15px;
  padding-right: 8px;
  padding-left: 3px;
}

.experiences img {
  height: min(60%, 500px);
  width: min(95%, 800px);
  margin: 5rem auto;
  margin-bottom: 3rem;
  object-fit: cover;
}

.things-to-see-container {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  justify-content: center;
  width: min(90%, 800px);
  align-items: center;
  margin: 5rem auto;
}
.things-to-see-container img {
  height: min(120vw, 650px);
  width: min(90vw, 400px);
  margin: 0 1rem;
  object-fit: cover;
}
.location-description-container {
  height: 650px;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.location-description-container p {
  font-size: 0.9rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-dark-accent);
}
.location-description-container p b {
  text-decoration: none;
  /* font-size: 0.95rem; */
  font-weight: 600;
}
.location-description-container p:last-of-type {
  border: none;
}
.default-p {
  opacity: 0.3;
  cursor: pointer;
  transition: all 0.3s;
}
.hovered-p {
  opacity: 0.5;
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}
.active-p {
  opacity: 1;
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
}

.table-1-col-4-row {
  background: var(--color-dark);
  padding-top: 2rem;
  padding-bottom: 7rem;
}
.table-wrapper {
  width: 1136px;
  width: min(85%, 1136px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.table-row {
  display: flex;
  width: 700px;
  width: min(100%, 700px);
  padding: 1.5rem;
  border-bottom: 1px solid var(--color-dark-accent);
  justify-content: space-between;
}
.table-row:first-of-type {
  border-top: 1px solid var(--color-dark-accent);
}
.table-row h2 {
  color: var(--color-light);
  font-size: 2rem;
  font-weight: 400;
}
.btn-white-fill-sq-md {
  background: var(--color-light);
  border-radius: 0;
  font-size: 1.5rem;
  margin: 5rem;
  cursor: pointer;
}
.btn-white-fill-sq-md:hover {
  background: var(--color-light-accent);
  color: var(--color-dark);
}

@media screen and (max-width: 800px) {
  .city-image-main {
    top: 28%;
  }
  .location-support-info {
    height: max(65%, 950px);
  }
  .location-support-info-card-container {
    flex-direction: column;
  }
  .p-container {
    flex-direction: column;
    align-items: center;
  }
}

footer {
  background: black;
  padding-top: 4rem;
  padding-bottom: 5rem;
}
footer h2 {
  color: var(--color-light);
  font-size: 1.4rem;
}
footer p {
  color: var(--color-light-accent);
}

.footer-wrapper {
  width: clamp(200px, 100%, 1136px);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.company-info,
.company-contact,
.footer-trips {
  width: 250px;
  margin: 1rem;
}

.contact-detail {
  display: flex;
  align-items: center;
}

.footer-icon {
  height: 25px;
  width: 25px;
  margin-right: 15px;
}

.footer-recent-trips-image-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.footer-recent-trips-image {
  height: 75px;
  width: 75px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.socials-container {
  display: flex;
  justify-content: center;
}
.socials-container img {
  cursor: pointer;
  height: 40px;
  margin: 0.7rem 0.5rem;
}

@media screen and (max-width: 750px) {
  .footer-wrapper {
    flex-direction: column;
    align-items: center;
  }
}
