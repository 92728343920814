.header-wrapper {
  position: relative;
  display: flex;
  width: clamp(200px, 100%, 1136px);
  margin: auto;
  height: 90px;
  align-items: center;
  font-family: var(--font-primary);
}

.logo-container {
  margin: 0 30px;
  font-size: 1.2rem;
  font-weight: 500;
}

nav {
  display: flex;
  justify-content: right;
  align-items: center;
  position: absolute;
  right: 0;
  margin: 0 30px;
}

nav > a,
nav > div {
  margin-left: 70px;
}

header a:hover {
  opacity: 0.5;
}

.hamburger {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.line {
  height: 2px;
  width: 20px;
  background: var(--color-dark);
  margin: 2px 0;
  transition: all 0.3s;
}

.line.half {
  width: 10px;
  margin-left: 10px;
}

.hamburger:hover .line.half {
  width: 20px;
  margin-left: 0px;
}

.hidden-menu {
  position: fixed;
  top: 0;
  right: 0;
  background: var(--color-dark);
  height: 100vh;
  min-width: 450px;
  max-width: 100%;
  z-index: 99;
  overflow: hidden;
  transition: all 0.3s;
}

.close-hidden-menu {
  color: var(--color-light);
  font-size: 3rem;
  position: absolute;
  top: 0;
  right: 0;
  margin: 6rem 4rem;
  cursor: pointer;
  transition: all 0.5s;
}
.close-hidden-menu:hover {
  transform: rotate(90deg);
}

.hidden-menu-text-container {
  width: 1px;
  margin: 4rem;
  padding: 1rem;
  white-space: nowrap;
}

.menu-link {
  color: var(--color-light);
  font-size: 3rem;
  margin: 1rem;
  transition: all 0.3s;
}
.menu-link:hover {
  font-style: italic;
  text-decoration: line-through;
}

@media screen and (max-width: 650px) {
  .nav-link {
    display: none;
  }
}
