.grey-background {
  background: rgb(248, 248, 248);
}

.text-1-col {
  background: var(--color-light);
  padding: 4rem 1rem;
  padding-bottom: 7rem;
}

.text-wrapper {
  width: clamp(200px, 100%, 850px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin: auto;
}
.text-wrapper h2 {
  margin: 1.5rem 0rem;
  font-size: clamp(2rem, 5vw, 2.5rem);
}
.text-wrapper p {
  margin: 1rem 0;
  font-size: clamp(1rem, 2vw, 1.2rem);
}
