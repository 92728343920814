.location-name {
  position: relative;
  height: min(210vh, 1900px);
  background: var(--color-light);
  display: flex;
  align-items: center;
  flex-direction: column;
  opacity: 0;
}

.location-name h2 {
  position: absolute;
  z-index: 6;
  top: 0;
  font-size: clamp(3rem, 15vw, 13rem);
  margin: 0;
  margin-top: 4rem;
  font-weight: 400;
  letter-spacing: 0.6rem;
}

.city-image-main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  height: min(130vw, 1200px);
  width: min(90%, 1136px);
  object-fit: cover;
}

.location-support-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--color-dark);
  height: 950px;
  height: max(50%, 950px);
}
.location-support-info-card-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: min(75vw, 1136px);
  margin: auto;
  position: absolute;
  z-index: 6;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding-bottom: 3rem;
}
.location-support-info-card {
  width: 275px;
  height: 225px;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.location-support-info-card h3 {
  font-size: 6rem;
  font-size: clamp(3rem, 15vw, 6rem);
  font-weight: 400;
  margin: 0;
  margin-bottom: 1rem;
  color: var(--color-light);
}
.location-support-info-card p {
  margin: 0;
  color: var(--color-light-accent);
}

.experiences {
  background: var(--color-dark);
}
.experiences-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 5rem;
  width: clamp(200px, 85%, 1136px);
  margin: auto;
  padding: 5rem 0rem;
}
.experiences h2 {
  color: var(--color-light);
  font-size: clamp(3rem, 5vw, 5rem);
  width: min(80%, 500px);
  margin: 3rem 2rem;
}
.p-container {
  margin: 1rem 2rem;
  display: flex;
  justify-content: space-evenly;
}
.experiences p {
  width: 300px;
  color: var(--color-light-accent);
  font-size: 1.2rem;
}

.experiences .p-container-first:first-of-type::first-letter {
  color: var(--color-light);
  font-family: var(--font-primary);
  float: left;
  font-size: 5.5rem;
  line-height: 60px;
  padding-top: 15px;
  padding-right: 8px;
  padding-left: 3px;
}

.experiences img {
  height: min(60%, 500px);
  width: min(95%, 800px);
  margin: 5rem auto;
  margin-bottom: 3rem;
  object-fit: cover;
}

.things-to-see-container {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  justify-content: center;
  width: min(90%, 800px);
  align-items: center;
  margin: 5rem auto;
}
.things-to-see-container img {
  height: min(120vw, 650px);
  width: min(90vw, 400px);
  margin: 0 1rem;
  object-fit: cover;
}
.location-description-container {
  height: 650px;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.location-description-container p {
  font-size: 0.9rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-dark-accent);
}
.location-description-container p b {
  text-decoration: none;
  /* font-size: 0.95rem; */
  font-weight: 600;
}
.location-description-container p:last-of-type {
  border: none;
}
.default-p {
  opacity: 0.3;
  cursor: pointer;
  transition: all 0.3s;
}
.hovered-p {
  opacity: 0.5;
  transform: scale(1.03);
}
.active-p {
  opacity: 1;
  transform: scale(1.07);
}

.table-1-col-4-row {
  background: var(--color-dark);
  padding-top: 2rem;
  padding-bottom: 7rem;
}
.table-wrapper {
  width: 1136px;
  width: min(85%, 1136px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.table-row {
  display: flex;
  width: 700px;
  width: min(100%, 700px);
  padding: 1.5rem;
  border-bottom: 1px solid var(--color-dark-accent);
  justify-content: space-between;
}
.table-row:first-of-type {
  border-top: 1px solid var(--color-dark-accent);
}
.table-row h2 {
  color: var(--color-light);
  font-size: 2rem;
  font-weight: 400;
}
.btn-white-fill-sq-md {
  background: var(--color-light);
  border-radius: 0;
  font-size: 1.5rem;
  margin: 5rem;
  cursor: pointer;
}
.btn-white-fill-sq-md:hover {
  background: var(--color-light-accent);
  color: var(--color-dark);
}

@media screen and (max-width: 800px) {
  .city-image-main {
    top: 28%;
  }
  .location-support-info {
    height: max(65%, 950px);
  }
  .location-support-info-card-container {
    flex-direction: column;
  }
  .p-container {
    flex-direction: column;
    align-items: center;
  }
}
