footer {
  background: black;
  padding-top: 4rem;
  padding-bottom: 5rem;
}
footer h2 {
  color: var(--color-light);
  font-size: 1.4rem;
}
footer p {
  color: var(--color-light-accent);
}

.footer-wrapper {
  width: clamp(200px, 100%, 1136px);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.company-info,
.company-contact,
.footer-trips {
  width: 250px;
  margin: 1rem;
}

.contact-detail {
  display: flex;
  align-items: center;
}

.footer-icon {
  height: 25px;
  width: 25px;
  margin-right: 15px;
}

.footer-recent-trips-image-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.footer-recent-trips-image {
  height: 75px;
  width: 75px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.socials-container {
  display: flex;
  justify-content: center;
}
.socials-container img {
  cursor: pointer;
  height: 40px;
  margin: 0.7rem 0.5rem;
}

@media screen and (max-width: 750px) {
  .footer-wrapper {
    flex-direction: column;
    align-items: center;
  }
}